//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/pages/dashboard/total
// Description: Consolidated accounts page.
// Created:     FUB, June 06, 2022
//==============================================================================

import React, { useEffect, useState } from "react"

import { Page } from "../../../components/layout/page"
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs"
import { TableSimple } from "../../../components/widgets/table"
import { useMembership } from "../../../utils/member-data"
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button"
import { CumulativeReturns } from "../../../components/widgets/cumulative-returns"
import { useAnnualReturns, AnnualReturns } from "../../../components/widgets/annual-returns"
import {
    AccountGroupPortfolioTable, AccountAssetAllocationTable, AccountAssetAllocationTableDummy,
    FinishRebalancing, AccountAssetAllocationDownload
} from "../../../components/widgets/rebal-asset-alloc"
import { Popup } from "../../../components/widgets/popup"
import { useAccountGroupIndices, accountGroupAllAccounts, useBacktestSpec, useBacktest } from "../../../utils/account-data"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`TOTAL: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`TOTAL: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------

const Backtest = ({ ids }) => {
    const spec = useBacktestSpec(ids)
    const backtest = useBacktest(spec)
    const annual = useAnnualReturns(backtest?.chartTable)

    return backtest ? (<>
        <p>
            This table shows the backtested metrics based on
            the strategy tranches from above:
        </p>
        <TableSimple data={backtest?.metricsTable} />
        <p>
            This chart shows the backtested performance based
            on the strategy tranches from above:
        </p>
        <CumulativeReturns data={backtest?.chartTable} />
        <p>
            This chart shows the backtested annual returns:
        </p>
        <AnnualReturns data={annual} />
    </>
    ) : (<>
        <p>Running backtest&hellip;</p>
    </>)
}

//------------------------------------------------------------------------------
const TotalPage = ({ location }) => {
    const membership = useMembership()
    const allAccounts = useAccountGroupIndices(accountGroupAllAccounts)
    const hasPremium = membership?.features.premium.hasAccess
    const hasInfinite = membership?.features.infinite.hasAccess
    const hasBacktest = hasInfinite || (hasPremium && membership?.level?.year < 2022)
    const hasAlloc = hasInfinite

    const [showBacktest, setShowBacktest] = useState(false)
    const [showBacktestNag, setShowBacktestNag] = useState(false)

    const clickShowBacktest = () => {
        setShowBacktest(hasBacktest)
        setShowBacktestNag(!hasBacktest)
    }

    const [theIds, setIds] = useState(null)
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const ids0 = searchParams.get("ids")

        let ids = null
        try {
            ids = JSON.parse(ids0)
        }
        catch (error) {
            // failed to parse - ignore
        }

        setIds(ids ?? allAccounts)
    }, [location, allAccounts])
    //DEBUG_MSG(`theIds=${JSON.stringify(theIds)}`)

    const title = theIds ? "Account-Group Analysis" : "Consolidated Account Analysis"

    return (
        <Page
            title={title}
            description="Use this page to view consolidated charts and metrics across your investment accounts."
        >

            <h1>{title}</h1>
            <Breadcrumbs to="/dashboard/" text="Dashboard" />

            <h2>Portfolio Composition by Strategy</h2>
            <AccountGroupPortfolioTable ids={theIds} />

            <h2>Consolidated Asset Allocation</h2>
            <p>
                This table shows the consolidated asset allocation based on
                the strategy tranches from above:
            </p>
            {hasAlloc ? (<>
                <AccountAssetAllocationTable ids={theIds} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <small>
                    <AccountAssetAllocationDownload ids={theIds} />
                </small>
                <FinishRebalancing ids={theIds} />
            </>) : (<>
                <AccountAssetAllocationTableDummy />
            </>)}

            <h2>Backtest</h2>
            {showBacktest ? (<>
                <Backtest ids={theIds} />
            </>) : (<>
                <p>
                    We can backtest the historical performance of your consolidated accounts.
                </p>
                <ButtonSecondary text="run backtest" onClick={clickShowBacktest} />
            </>)}

            {showBacktestNag && (
                <Popup
                    title="Almost There..."
                    onCancel={() => setShowBacktestNag(false)}
                >
                    <p>
                        Backtesting of consolidated accounts is available
                        with our <strong>Infinite</strong> membership.
                    </p>
                    <ButtonPrimary text="upgrade now" to="/sign-up/" />
                </Popup>
            )}

            <br /><br />
        </Page >
    )
}

export default TotalPage

//==============================================================================
// end of file
