//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/rebal-asset-alloc
// Description: Asset allocation for rebalancing.
// Created:     FUB, July 19, 2022
//==============================================================================

import React, { useState, useEffect } from "react"
import { FaFileDownload } from "react-icons/fa"

import { Link, AssetLink } from "./link"
import { TableSimple, TableSortable } from "./table"
import { Border } from "./container"
import { ButtonPrimary, ButtonHelp } from "./button"
import { formatCurrency, formatPercent } from "../../utils/format-helpers"
import { useAccountGroupInfo } from "../../utils/account-data"
//import { useMembership } from "../../utils/member-data"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`REBAL-ALLOC: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`REBAL-ALLOC: ${msg}`) // eslint-disable-line no-unused-vars

//##############################################################################

//------------------------------------------------------------------------------
/**
 * Component to render dummy asset allocation table.
 * 
 * Members without access to Infinite features see a dummy table.
 * The table contains a message box with a CTA to upgrade their membership.
 * 
 * @returns HTML table
 */
export const AccountAssetAllocationTableDummy = () => {
    // this code is duplicated from components/widgets/portfolio/asset-allocation.js
    const space = <span>&nbsp;</span>
    const ellipsis = <span>&hellip;</span>
    /*const box = <em><center>
        [This table intentionally left blank]
    </center></em>*/
    const box = <Border>
        <center>
            <p>
                You can see the consolidated asset allocation for your investments
                with our <strong>Infinite membership</strong>.
            </p>
            <ButtonPrimary text="upgrade your membership" to="/sign-up/" />

        </center>
    </Border>

    const data = {
        columns: ["Symbol", "Name", "Allocation"],
        "Symbol": [ellipsis, ellipsis, ellipsis],
        "Name": [space, box, space],
        "Allocation": [ellipsis, ellipsis, ellipsis],
    }

    return (<>
        <TableSimple data={data} />
    </>)
}

//------------------------------------------------------------------------------
/**
 * Component to render 'finish rebalancing' button.
 * 
 * @param {*} alloc consolidated asset allocation  
 */
export const FinishRebalancing = ({ ids }) => {
    const accountInfo = useAccountGroupInfo(ids)
    const needsRebal = accountInfo?.needsRebalancing

    const finishRebalancing = () => {
        accountInfo.finishRebalancing()
    }

    return needsRebal ? (<>
        <ButtonPrimary text="finish rebalancing" onClick={finishRebalancing} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <ButtonHelp text="how to rebalance your account" to="/help/account-rebalancing" />
    </>) : null
}

//------------------------------------------------------------------------------
export const AccountGroupPortfolioTable = ({ ids }) => {
    const accountData = useAccountGroupInfo(ids)

    const [theTable, setTable] = useState()
    useEffect(() => {
        if (!accountData || !accountData.portfolios) return
        //DEBUG_MSG(`accountData=${JSON.stringify(accountData)}`)

        const table = {
            columns: ["Portfolio", "Allocation"],
            "Portfolio": accountData?.portfolios.map(portfolio => <Link href={`/portfolios/${portfolio}`}>{`${accountData[portfolio].author} ${accountData[portfolio].name}`}</Link>),
            "Portfolio-TextOnly": accountData?.portfolios.map(portfolio => `${accountData[portfolio].author} ${accountData[portfolio].name}`),
            "Allocation": accountData?.portfolios.map(portfolio => `${formatPercent(accountData[portfolio]?.allocation * 100)} / ${formatCurrency(accountData[portfolio]?.value, 0)}`),
        }
        const sort = {
            defaultColumn: "Allocation",
            "Portfolio": { alpha: true, ascending: true, sortBy: "Portfolio-TextOnly" },
            "Allocation": { alpha: false, ascending: false },
        }
        setTable({ table, sort })
    }, [accountData])

    return theTable ? (<TableSortable data={theTable?.table} sort={theTable?.sort} />) : null

}

//------------------------------------------------------------------------------
export const AccountAssetAllocationTable = ({ ids }) => {
    const accountDataRaw = useAccountGroupInfo(ids)
    const filterAssets = ids?.length > 1
    const accountData = {
        ...accountDataRaw,
        assets: accountDataRaw?.assets?.filter(ticker => !filterAssets || accountDataRaw[ticker].allocation > 0 || accountDataRaw[ticker].current > 0)
    }

    //const membership = useMembership()
    const showDelta = true //membership?.features.felix.hasAccess

    if (!accountData?.assets)
        return <p>Loading asset allocation&hellip;</p>

    const table = {
        columns: showDelta ? ["Symbol", "Name", "Target Allocation", "Current", "Order"] : ["Symbol", "Name", "Target Allocation"],
        //columns: ["Symbol", "Name", "Target Allocation", "Current", "Order"],
        "Symbol": accountData.assets.map(ticker => ticker !== "---" ? <AssetLink ticker={ticker} /> : ticker),
        "Symbol-TextOnly": accountData.assets,
        "Name": accountData.assets.map(ticker => accountData[ticker].name),
        "Target Allocation": accountData.assets.map(ticker => ticker !== "---" ?
            (accountData[ticker].allocation !== 0 ? `${formatPercent(accountData[ticker].allocation * 100)} / ${Math.sign(accountData[ticker].shares) * Math.floor(Math.abs(accountData[ticker].shares))} shares` : "---") :
            `${formatPercent(accountData[ticker].allocation * 100)} / ${formatCurrency(accountData[ticker].allocation * accountData.value, 0)}`),
        "Current": accountData.assets.map(ticker => ticker !== "---" ?
            (accountData[ticker].current !== 0 ? `${Math.sign(accountData[ticker].current) * Math.floor(Math.abs(accountData[ticker].current))} shares` : "---") :
            "---"),
        "Order": accountData.assets.map(ticker => ticker !== "---" ?
            (accountData[ticker].order >= 1 ? `buy ${Math.floor(accountData[ticker].order)} shares` : (
                accountData[ticker].order <= -1 ? `sell ${Math.floor(-accountData[ticker].order)} shares` :
                    "---")) :
            "---"),
        "Order-Size": accountData.assets.map(ticker => ticker !== "---" ? Math.abs(accountData[ticker].order) : 0),
    }

    const sort = {
        defaultColumn: "Order",
        "Symbol": { alpha: true, ascending: true, sortBy: "Symbol-TextOnly" },
        "Name": { alpha: true, ascending: true },
        "Target Allocation": { alpha: false, ascending: false },
        "Current": { alpha: false, ascending: false },
        "Order": { alpha: false, ascending: false, sortBy: "Order-Size" },
    }

    //return <p>{JSON.stringify(table)}</p>
    return (<TableSortable data={table} sort={sort} />)
}

export const AccountAssetAllocationDownload = ({ ids, fileName }) => {
    const accountData = useAccountGroupInfo(ids)

    const [theUrl, setUrl] = useState()
    useEffect(() => {
        if (!accountData?.assets)
            return

        const data = accountData.assets
            .filter(ticker => ticker !== "---")
            .filter(ticker => accountData[ticker].allocation > 0 || accountData[ticker].current > 0)
            .reduce(
                (acc, ticker) => `${acc}\n${ticker},${accountData[ticker].name},${accountData[ticker].allocation * 100}%,${Math.floor(accountData[ticker].shares)}`,
                "SYMBOL,NAME,ALLOCATION,SHARES")

        const blob = new Blob([data], { type: "text/csv" })
        const url = URL.createObjectURL(blob)
        setUrl(url)

        return () => URL.revokeObjectURL(url)
    }, [accountData])

    return (
        <a href={theUrl} download={`${fileName ?? "asset-allocation"}.csv`}>
            <FaFileDownload />
            &nbsp;Download as CSV<br /><br />
        </a>
    )
}

//==============================================================================
// end of file
